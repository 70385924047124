import Axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CryptoJS from "crypto-js";
import moment from "moment";

let KEY = process.env.REACT_APP_SECTET_KEY;

const Helper = {

    Baseurl: function () {

        return "https://dljfjefnqnjf.cryptomend.com/user"


    },
    SocketBaseurl: () => {


        return "https://dljfjefnqnjf.cryptomend.com/"

    },

    PostData: async function (url, params, type) {
        let axiosConfig;

        let APIToken = await Helper?.signToken({
            issue: KEY,
            exp: (moment().unix() + 5 * 60) * 1000,
        });

        if (type == "formdata") {

            axiosConfig = {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Access-Control-Allow-Origin": "false",
                    "x-access-token": localStorage.getItem("crypto.com-access-key"),
                    "cikmhnwjvt": APIToken
                },
            }
        } else {
            if (localStorage.getItem("crypto.com-access-key")) {
                axiosConfig = {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": "true",
                        "x-access-token": localStorage.getItem("crypto.com-access-key"),
                        "cikmhnwjvt": APIToken
                    },
                };
            } else {
                axiosConfig = {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": "true",
                        "cikmhnwjvt": APIToken
                    },
                };
            }
        }

        return await Axios.post(url, params, axiosConfig)
            .then((res) => {
                try {
                    if (res.status === 200) {
                        let output = res.data;
                        if (output.status === true) {
                            toast.success(output.message);
                            return output;
                        } else {
                            if (output.message === "Session Timed Out") {
                                toast.error("Your Session is Over Please Logout !");
                                setTimeout(() => {
                                    localStorage.clear();
                                    window.location.href = "/"
                                }, 1000)
                            }


                            else if (output.message === "Account Deactivated") {
                                toast.error("Your Account Deactivated");
                                setTimeout(() => {

                                    window.location.href = "/chat"
                                }, 500)

                            }

                            else if (output.message === "Account Deleted") {
                                toast.error("Your Account Deleted");
                                setTimeout(() => {
                                    localStorage.clear();
                                    window.location.href = "/"
                                }, 500)

                            }
                            else
                                toast.error(output.message);
                        }
                        return output;
                    }
                } catch (e) {
                    toast.error("Internal Server Error!");
                }
            })
            .catch((e) => {
                toast.error("Internal Server Error!");
                localStorage.clear();
            });
    },

    GetData: async function (url) {
        let axiosConfig;
        let APIToken = await Helper?.signToken({
            issuer: KEY,
            exp: (moment().unix() + 5 * 60) * 1000,
        });

        if (localStorage.getItem("crypto.com-access-key")) {
            axiosConfig = {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "true",
                    "x-access-token": localStorage.getItem("crypto.com-access-key"),
                    "cikmhnwjvt": APIToken
                },
            };
        } else {
            axiosConfig = {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "true",
                    "cikmhnwjvt": APIToken
                },
            };
        }

        return Axios.get(url, axiosConfig)
            .then((res) => {
                return res?.data;
            })
            .catch((e) => {
                if (e.response.status === 401) {
                    localStorage.clear();
                } else {
                    return { status: false, message: e };
                }

            });
    },

    isValidFile: async function (event, files, type) {
        return new Promise((resolve, reject) => {
            var reader = new FileReader();
            reader.readAsArrayBuffer(event?.target?.files[0]);
            reader.onloadend = (e) => {
                var bytes = new Uint8Array(e.target.result).subarray(0, 4);
                let header = '';
                for (let i = 0; i < bytes.length; i++) {
                    header += bytes[i].toString(16);
                }
                if ((bytes[0] != 0xFF && bytes[1] != 0xD8 && bytes[0] != 0x89 && bytes[1] != 0x50 && bytes[0] != 0x00 && bytes[0] != 0x3C && bytes[1] != 0x73) || (event.target.files && event.target.files[0].type != 'image/png' && event.target.files[0].type != 'image/jpg' && event.target.files[0].type != 'image/jpeg')) {
                    toast.error('Invalid image format !');
                    resolve(false);
                } else {
                    const img = new Image(); img.src = URL.createObjectURL(event.target.files[0]);
                    img.onload = () => {
                        const width = img.naturalWidth;
                        const height = img.naturalHeight;
                        if (width > 6000 || height > 6000) {
                            toast.error('The image dimensions should be 6000 x 6000 pixels');
                            resolve(false);
                        }
                        else {
                            if (event.target.files && event.target.files[0]) {
                                var file = files;
                                let typeFile = file.type;
                                let file_size = file.size / 1024 / 1024;
                                if (typeFile != 'image/png' && typeFile != 'image/jpg' && typeFile != 'image/jpeg' && typeFile != 'image/svg+xml' && typeFile != 'image/avif') {
                                    toast.error('Invalid image format !')
                                    resolve(false);
                                }
                                else if (file_size >= 2) {
                                    toast.error('Upload image should be less than or equal to 2MB');
                                    resolve(false);
                                }
                                else {
                                    resolve(true)
                                }
                            }
                        }
                    };
                }
            }
        })
    },

    numberFormater: (value) => {
        const formatter = new Intl.NumberFormat('en-US');
        const formattedNumber = formatter.format(value);
        return formattedNumber;
    },


    base64url: (source) => {
        let encodedSource = CryptoJS.enc.Base64.stringify(source);
        return encodedSource;
    },
    encodeToken: (payload) => {
        var header = { alg: "HS256", typ: "JWT" };
        var stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
        var encodedHeader = Helper?.base64url(stringifiedHeader);
        var stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(payload));
        var encodedData = Helper?.base64url(stringifiedData);
        return encodedHeader + "." + encodedData;
    },
    signToken: (payload) => {
        let token = Helper?.encodeToken(payload);
        var signature = CryptoJS.HmacSHA256(token, KEY);
        signature = Helper?.base64url(signature);
        var signedToken = token + "." + signature;
        return signedToken;
    },

    formatDated: function (date) {
        date = new Date(date);
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var seconds = date.getSeconds();
        var dates = date.getDate();
        var months = date.getMonth() + 1;
        var years = date.getFullYear();
        var ampm = hours >= 12 ? "PM" : "AM";
        hours = hours % 12;
        hours = hours ? hours : 12;
        hours = hours < 10 ? "0" + hours : hours;
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        dates = dates < 10 ? "0" + dates : dates;
        months = months < 10 ? "0" + months : months;
        var strTime = hours + ":" + minutes + ":" + seconds + " " + ampm;
        return dates + "/" + months + "/" + years + " " + strTime;
    },



    formatNumberWithCommas: function (number) {
        // Convert number to string to facilitate manipulation
        let numStr = number.toString();

        // Split the number into parts (before and after the decimal point)
        let parts = numStr.split('.');

        // Part before the decimal point
        let integerPart = parts[0];

        // Regex to match groups of three digits from the end
        let regex = /(\d)(?=(\d{3})+(?!\d))/g;

        // Replace the matched groups with the group followed by a comma
        integerPart = integerPart.replace(regex, '$1,');

        // Check if there is a decimal part
        if (parts.length > 1) {
            // Retain only the first two decimal places
            let decimalPart = parts[1].substring(0, 2);

            // Combine the integer part with the truncated decimal part
            return integerPart + '.' + decimalPart;
        } else {
            return integerPart;  // Return the formatted integer part if there is no decimal part
        }
    },


    loanNumberWithCommas: function (number) {

        let numStr = number.toString();


        let parts = numStr.split('.');

        let integerPart = parts[0];

        // Regex to match groups of three digits from the end
        let regex = /(\d)(?=(\d{3})+(?!\d))/g;

        // Replace the matched groups with the group followed by a comma
        integerPart = integerPart.replace(regex, '$1,');

        // Check if there is a decimal part
        if (parts.length > 1) {
            // Use toFixed() to retain only the first two decimal places and convert back to string
            let decimalPart = (parseFloat('0.' + parts[1]) || 0).toFixed(2).split('.')[1];

            // Combine the integer part with the formatted decimal part
            return integerPart + '.' + decimalPart;
        } else {
            return integerPart;  // Return the formatted integer part if there is no decimal part
        }
    },

    getTwentyFourHoursFormat: function (dbDate) {

        const date = new Date(dbDate);

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        const formattedDate = `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;

        return formattedDate;

    },

    copyFunction: function (value, message) {
        let copyText = value;
        let selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = copyText;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        toast.info(message);
    }

    ,

    checkSpecialChar: function (e) {
        if (!/[0-9a-zA-Z ]/.test(e.key)) {
            e.preventDefault();
        }
    }
};


export default Helper;
