import React, { useState } from "react";

import Sidebar from "../../common/Sidebar/Sidebar";

import lftarr from "../../assets/images/left-arrow-blue.png"
import sidenav from "../../assets/images/sidenav.png"

import intel from "../../assets/images/intel.png"
import usdtmini from "../../assets/images/usdt-mini.png"
import intro from "../../assets/images/intro.png"
import host from "../../assets/images/host-order.png"

import { useNavigate } from "react-router";


const Arbitrage = (props) => {


    const navigate = useNavigate();

    const [isActive, setIsActive] = useState(false);
    const SideNavClick = () => {
        setIsActive(!isActive);
    };


    return (

        <div className="MainBg">
            <div className="CntLdDv">
                <div className={isActive ? "HmPg  sbMenu ArbRgePg" : "HmPg ArbRgePg"}>
                    <Sidebar setActive={SideNavClick} />
                    <div className="container">


                        <div className="DfltTp">
                            <div className="DfltTpIg">
                                <button onClick={() => navigate(-1)} style={{ all: "unset", cursor: "pointer" }} >
                                    <img src={lftarr} />
                                </button>
                                <button onClick={SideNavClick} style={{ all: "unset", cursor: "pointer" }}>
                                    <img src={sidenav} />

                                </button>
                            </div>
                            <h6>Hosting work</h6>
                            <h2>$ 20.00154</h2>
                            <button className="btn HstOrdBtn my-4" onClick={() => navigate("/hostinghistory")} ><img src={host} className="mr-2" />Hosting Order</button>
                        </div>
                        <div className="ArbRgeIntroVl">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="ArbRgeIntroVlDts">
                                        <div className="ArbRgeIntroVlDtsL">
                                            <div className="text-center">
                                                <h6>Total Arbitrage</h6>
                                                <h5>20 USDT</h5>
                                            </div>
                                            <div className="text-center">
                                                <h6>Total Earnings</h6>
                                                <h5>88.00 USDT</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 pl-lg-0">
                                    <div className="ArbRgeIntroVlDts">
                                        <div className="ArbRgeIntroVlDtsR">
                                            <div>
                                                <a href="#">Introduction</a>
                                                <h6>How does Ai robot work</h6>
                                            </div>
                                            <img src={intro} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ArbRgePrdDv">
                            <h3>Arbitrage Products</h3>
                            <div className="ArbRgePrdDvDts">
                                <div className="ArbRgePrdDvDtsTp">
                                    <button className="btn DaysBtn mr-3">7 Days</button>
                                    <h4>BITX V1</h4>
                                </div>
                                <div className="ArbRgePrdDvDtsBtm">
                                    <div className="mb-2">
                                        <h5>Airbitage coin types</h5>
                                        <img src={usdtmini} />
                                    </div>
                                    <div className="mb-2">
                                        <h5>Amount</h5>
                                        <h6>$100 - 10000</h6>
                                    </div>
                                    <div className="mb-2">
                                        <h5>Daily Income</h5>
                                        <h6>0.3000%</h6>
                                    </div>
                                    <div className="mb-2">
                                        <button className="btn CmnBtn" style={{ minWidth: '121px' }} onClick={() => navigate("/joinarbitrage")}>Check</button>
                                    </div>
                                </div>
                            </div>
                            <div className="ArbRgePrdDvDts">
                                <div className="ArbRgePrdDvDtsTp">
                                    <button className="btn DaysBtn mr-3">15 Days</button>
                                    <h4>BITX V1</h4>
                                </div>
                                <div className="ArbRgePrdDvDtsBtm">
                                    <div className="mb-2">
                                        <h5>Airbitage coin types</h5>
                                        <img src={usdtmini} />
                                    </div>
                                    <div className="mb-2">
                                        <h5>Amount</h5>
                                        <h6>$100 - 10000</h6>
                                    </div>
                                    <div className="mb-2">
                                        <h5>Daily Income</h5>
                                        <h6>0.3000%</h6>
                                    </div>
                                    <div className="mb-2">
                                        <button className="btn CmnBtn" style={{ minWidth: '121px' }} onClick={() => navigate("/joinarbitrage")} >Check</button>
                                    </div>
                                </div>
                            </div>
                            <div className="ArbRgePrdDvDts">
                                <div className="ArbRgePrdDvDtsTp">
                                    <button className="btn DaysBtn mr-3">30 Days</button>
                                    <h4>BITX V1</h4>
                                </div>
                                <div className="ArbRgePrdDvDtsBtm">
                                    <div className="mb-2">
                                        <h5>Airbitage coin types</h5>
                                        <img src={usdtmini} />
                                    </div>
                                    <div className="mb-2">
                                        <h5>Amount</h5>
                                        <h6>$100 - 10000</h6>
                                    </div>
                                    <div className="mb-2">
                                        <h5>Daily Income</h5>
                                        <h6>0.3000%</h6>
                                    </div>
                                    <div className="mb-2">
                                        <button className="btn CmnBtn" style={{ minWidth: '121px' }} onClick={() => navigate("/joinarbitrage")} >Check</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ArbRgeIntroVl">
                            <div className="ArbRgeIntroVlDts">
                                <div className="ArbRgeIntroVlDtsR">
                                    <div>
                                        <a href="#">Ai Intelligent Moving Brick</a>
                                        <h6>Buy low and sell high to make a profit</h6>
                                    </div>
                                    <img src={intel} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    );

}

export default Arbitrage;