import React, { Fragment, useState, useMemo } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Modal,
  ModalBody,
} from "reactstrap";
import classnames from "classnames";
import sidenav from "../../assets/images/buy-coin-list.png";
import info from "../../assets/images/info.png";
import ethmini from "../../assets/images/eth-mini.png";
import closeMdl from "../../assets/images/close.png";
import usdtmini from "../../assets/images/usdt-mini.png";
import lftarr from "../../assets/images/left-arrow.png";
import copy from "../../assets/images/copy.png";
import pasteIg from "../../assets/images/pastIcon.svg"

import { useParams, useNavigate } from "react-router";

import { NumericFormat } from "react-number-format";
import { toast } from "react-toastify";


import QRCode from "react-qr-code";

import { useGetRequest, usePostRequest } from "../../Services/Queries";
import USER_APIS from "../../Services/APIS";

import { QueryKeys } from "../../Services/QueryKeys";
import { useCustomizeContext } from "../../Services/Context";
import OtpInput from "react-otp-input";
import Helper from "../../Services/Helper";


const Deposit = (props) => {

  let navigate = useNavigate();

  const [pinNumber, setPinNumber] = useState("");

  let { mutateAsync: sendWithdraw } = usePostRequest(
    QueryKeys?.SEND_WITHDRAW_REQUEST
  );
  let { mutateAsync: sendConvertRequest } = usePostRequest(
    QueryKeys?.SEND_CONVERT_REQUEST
  );

  const [tocurrUSDT, setTocurrUSDT] = useState("");
  const [convertFromAmount, setConvertFromAmount] = useState("");
  const [convertToAmount, setConvertToAmount] = useState("");
  const [currency, setCurrency] = useState('')
  const [toCoinId, setToCoinId] = useState("");



  const {
    loaderProperty: { setLoading },
    userInfo: { userObj },
    withdrawmessage: { setwithdrawdata }
  } = useCustomizeContext();

  const [activeTab, setActiveTab] = useState("1");

  const [currencyDetails, setCurrencyDetails] = useState("");

  const [minWithdraw, setMinWithdraw] = useState("");
  const [maxWithdraw, setMaxWithdraw] = useState("");

  let { id } = useParams();

  let requestData = {
    Api: `${USER_APIS?.GET_SINGLE_CURRENCY}/${id}`,
    Query_Key: QueryKeys?.GET_ONE_CURRENCY_KEY,
  };

  let { data: getOneCurrencyDetails } = useGetRequest(requestData);


  let getCurrencyInfoObj = {
    Api: `${USER_APIS?.GET_CURRENCY_DEAILS}/1`,
    Query_Key: QueryKeys?.GET_CURRENCY_KEY,
  };


  let { data: getCurrncyDetails } = useGetRequest(getCurrencyInfoObj);


  useMemo(() => {
    if (getCurrncyDetails?.status === true) {
      setCurrency(getCurrncyDetails?.data);
    }
  }, [getCurrncyDetails]);



  useMemo(() => {
    if (getOneCurrencyDetails?.status === true) {
      setCurrencyDetails(getOneCurrencyDetails?.data);
      setMinWithdraw(getOneCurrencyDetails?.data?.min_withdraw);

      setMaxWithdraw(
        (getOneCurrencyDetails?.data?.totalBalance -
          0.01).toFixed(2)
      );

    }
  }, [getOneCurrencyDetails]);



  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const [modal1, setModal1] = useState(false);
  const toggleGnrtPin = () => setModal1(!modal1);

  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [receiverAddress, setReceiverAddress] = useState("");

  const [isToastVisible, setIsToastVisible] = useState(false);


  // Function to set max amounts based on currencyDetails
  const handleMaxClick = (currencyDetails) => {

    if (currencyDetails.totalBalance !== 0 && currencyDetails.totalBalance !== 0.01) {
      const feePercentage = currencyDetails.fee || 0;
      const totalBalance = currencyDetails.totalBalance || 0;

      // Calculate the deducted amount by subtracting the fee percentage from the total balance
      const deductedAmount = totalBalance.toFixed(4) - (totalBalance.toFixed(4) * (feePercentage / 100) + 0.01);
      setConvertFromAmount(deductedAmount);
      // Calculate the corresponding amount in the destination currency (convertToAmount)
      const convertedAmount = deductedAmount * +currencyDetails.price;

      setConvertToAmount(convertedAmount.toFixed(2));

    } else if (!isToastVisible) {

      setIsToastVisible(true);
      toast.error("Insufficient funds", {
        onClose: () => {
          setIsToastVisible(false);
        }
      });


    }
  };


  // Function to set max amounts based on currencyDetails
  const handleMaxClickUSDT = (currency, tocurr) => {


    if (currencyDetails.totalBalance !== 0 && currencyDetails.totalBalance !== 0.01) {
      const feePercentage = currencyDetails.fee || 0;
      const totalBalance = currencyDetails.totalBalance || 0;

      // Calculate the deducted amount by subtracting the fee percentage from the total balance
      const deductedAmount = totalBalance - (totalBalance * (feePercentage / 100) + 0.01);

      // Find the selected currency object from the currency array
      const selectedCurrency = currency.find(crypto => crypto.currency_symbol === tocurr);

      if (selectedCurrency) {
        const toCurrencyPrice = selectedCurrency.price;


        // Calculate the amount that can be converted from the deducted amount based on the price
        const maxConvertibleAmount = deductedAmount / toCurrencyPrice;



        setConvertFromAmount(deductedAmount);
        setConvertToAmount(maxConvertibleAmount.toFixed(2));
      } else if (!isToastVisible) {


        setIsToastVisible(true);
        toast.error("Select To Coin", {
          onClose: () => {
            setIsToastVisible(false);
          }
        });

      }
    } else if (!isToastVisible) {

      setIsToastVisible(true);
      toast.error("Insufficient funds", {
        onClose: () => {
          setIsToastVisible(false);
        }
      });

    }
  };


  const withdrawRequest = () => {
    if (receiverAddress) {
      if (withdrawAmount) {


        if (+currencyDetails?.totalBalance > +withdrawAmount) {
          toggleGnrtPin();
        } else toast.error("Insufficient Funds !");


      } else {
        toast.error("Withdraw amount is required !");
      }
    } else {
      toast.error("Receiver address is required !");
    }
  };

  const copyFunction = () => {
    if (currencyDetails?.mxyurnfbve === "") {
      toast.info("No address found!");
    } else {
      navigator.clipboard.writeText(currencyDetails?.mxyurnfbve);
      toast.info("Address Copied!");

      setTimeout(() => {
      }, 2000);
    }
  };





  const pasteFunction = async (e) => {

    try {
      setTimeout(async () => {
        const text = await navigator.clipboard.readText();
        setReceiverAddress(text);
      }, 2000);

    } catch (error) {
      toast.error(error);

    }
  };


  const getSecureRandomNumber = () => {
    const array = new Uint32Array(1); window.crypto.getRandomValues(array); return array[0] % 100000000; // Adjust the range as needed    };
  }


  const sendRequest = async () => {
    const random = getSecureRandomNumber();

    let payload = {
      receiverAddress: receiverAddress,
      amount: withdrawAmount,
      withdarwPin: pinNumber,
      currencyId: id,
      userrandid: random

    };
    let postObj = {
      Api: USER_APIS?.SEND_WITHDRAW_REQUEST,
      Payload: payload,
      Type: "",
    };
    setLoading(true);
    let res = await sendWithdraw(postObj);
    if (res?.status === true) {

      setTimeout(() => {
        setLoading(false);

        setwithdrawdata(res?.data)
        navigate("/WithdrawMessage")


      }, 200);
    } else setLoading(false);
  };



  const AvoidSpace = (event) => {
    let k = event.key ? event.key : event.which;
    if (k === " ") event.preventDefault();
  };

  const convertRequest = async () => {

    if (convertFromAmount) {
      if (convertToAmount) {
        await handleConvertRequest()
      } else toast.error("To Amount USDT is Required !");
    } else
      toast.error(
        `From Amount ${currencyDetails?.currency_symbol} is Required !`
      );
  };


  const handleConvertRequest = async () => {
    if (currencyDetails?.totalBalance > convertFromAmount) {

      const toCurrencyId = currencyDetails?.currency_symbol === "USDT"
        ? toCoinId
        : "666996af01a86e02a9682c1b";

      const random = getSecureRandomNumber();

      let payload = {
        fromCurrencyId: id,
        fromAmount: convertFromAmount,
        toCurrencyId: toCurrencyId,
        toAmount: convertToAmount,
        convertFee: currencyDetails?.fee ? currencyDetails?.fee : 0,
        userrandid: random

      };

      let postObj = {
        Api: USER_APIS?.SEND_CONVERT_REQUEST,
        Payload: payload,
        Type: "",
      };
      setLoading(true);
      let res = await sendConvertRequest(postObj);

      if (res?.status === true) {
        setConvertFromAmount("");
        setConvertToAmount("");

        setTimeout(() => {
          setLoading(false);
          navigate(`/transactionhistory/${id}/?tab=3`);
        }, 1500);
      } else setLoading(false);

    } else toast.error("Insufficient Funds !");
  }


  const convertValue = (inputObj, currentcurrency) => {

    const { value } = inputObj;



    const currencySymbol = currencyDetails?.currency_symbol;
    const totalBalance = currencyDetails?.totalBalance;

    // Validate input value format
    const regex = /^\d*\.?\d{0,6}$/;
    if (!regex.test(value)) {

      return false;
    }
    // Check if total balance is sufficient
    if (totalBalance >= +value) {
      setConvertToAmount("");
      setConvertFromAmount(value);

      if (currencySymbol === "USDT") {
        const selectedCurrency = currency.find(crypto => crypto.currency_symbol === currentcurrency);
        if (selectedCurrency) {
          const toCurrencyPrice = selectedCurrency.price;

          const feePercentage = currencyDetails.fee || 0;
          let netValue = +value - (value * feePercentage / 100);


          const maxConvertibleAmount = netValue / toCurrencyPrice;


          setConvertToAmount(maxConvertibleAmount.toFixed(2));
        } else {
          toast.error("Selected currency not found");
        }
      } else {


        const feePercentage = currencyDetails.fee || 0;
        let netValue = +value - (value * feePercentage / 100);
        // Calculate the converted amount
        let cal = netValue * +currencyDetails?.price;




        setConvertToAmount(cal);


      }
      return true; // Return true after a successful conversion
    } else {

      toast.error("Amount should not be higher than the maximum amount!");
    }

    return false; // Return false if validation fails
  };



  const sendAmountFn = (inputObj) => {
    const { value } = inputObj;

    const regex = /^\d*\.?\d{0,2}$/;

    if (!regex.test(value)) {
      return false;
    }

    if (+maxWithdraw >= +value) {
      setWithdrawAmount(value);
      return true;
    } else {
      if (!isToastVisible) {
        setIsToastVisible(true);
        toast.error("Amount is should not be higherthan maximum amount !", {
          onClose: () => {
            setIsToastVisible(false);
          }
        });
      }
      return false;
    }
  };



  const checkSpecialChar = (e) => {
    if (!/[0-9a-zA-Z ]/.test(e.key)) {
      e.preventDefault();
    }
    else {
      setReceiverAddress(e.target.value)
    }
  };

  const handleAmount = (data, FindAmount) => {
    if (data) {
      if (!isNaN(+data.totalWalletAmount) && FindAmount == "totalWalletAmount") {
        return Helper?.numberFormater((+data.totalWalletAmount).toFixed(2))
      }
      else if (!isNaN(+data.totalBalance) && FindAmount == "totalBalance") {
        return Helper?.numberFormater((+data.totalBalance).toFixed(2))
      }
      else if (!isNaN(+data.totalHoldBalance) && FindAmount == "totalHoldBalance") {
        return Helper?.numberFormater((+data.totalHoldBalance).toFixed(2))
      }

      else {
        return "0.00"
      }
    }
    else {
      return "Loading..."
    }
  }

  const handleMaxFunction = (e) => {

    e.preventDefault();
    if (currencyDetails?.totalBalance === 0) {

      handleToast()

    } else if (currencyDetails?.totalBalance === 0.01) {
      handleToast()
    }
    else {
      setWithdrawAmount(maxWithdraw);
    }

  }

  const handleToast = () => {
    if (!isToastVisible) {
      setIsToastVisible(true);
      toast.error("Insufficient funds", {
        onClose: () => {
          setIsToastVisible(false);
        }
      });
    }
  }



  const handleCurrency = (data, FindAmount) => data == FindAmount ? "ERC20" : data

  const disableBtn = () => {
    if (userObj?.verified === false) {
      return false
    } else if (pinNumber.length === 4) {
      return false
    }
    else {
      return true
    }
  }


  const handleWithdrwBtn = () => !(receiverAddress.length > 0 && withdrawAmount.length > 0)

  return (
    <Fragment>
      <div className="MainInrBg">
        <div className="CntLdDv">
          <div className="HmPg DeptPg">
            <div className="container">
              <div className="DfltTp DeptPgTp">
                <div className="DfltTpIg mb-4">
                  <button onClick={() => navigate(-1)} style={{ all: "unset", cursor: "pointer" }}>
                    <img src={lftarr} alt="" />
                  </button>
                  <h4>{currencyDetails?.currency_symbol} Wallet</h4>
                  <button style={{ all: "unset", cursor: "pointer" }} onClick={() => navigate(`/transactionhistory/${id}`)}>
                    <img
                      src={sidenav}

                      alt=""
                    />
                  </button>
                </div>

                <h3>
                  US${" "}

                  {handleAmount(currencyDetails, "totalWalletAmount")}
                </h3>

                <div className="d-flex justify-content-center align-items-center">
                  <img
                    src={currencyDetails?.image}
                    width="20px"
                    height="20px"
                    className="mr-2"
                    alt=""

                  />
                  <div>
                    <p>
                      Available :{" "}

                      {handleAmount(currencyDetails, "totalBalance")}

                      {" "} {currencyDetails?.currency_symbol}
                    </p>
                    <p>
                      Frozen :{" "}

                      {handleAmount(currencyDetails, "totalHoldBalance")}
                      {" "}

                      {currencyDetails?.currency_symbol}
                    </p>
                  </div>
                </div>

                <div className="DeptTbs">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          toggle("1");
                        }}
                      >
                        Receive
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                          toggle("2");
                        }}
                      >
                        Withdraw
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "3" })}
                        onClick={() => {
                          toggle("3");
                        }}
                      >
                        Convert
                      </NavLink>
                    </NavItem>

                  </Nav>
                </div>
              </div>
              <div className="DeptDfltBg">
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <div className="DeptFdsDv">
                      <div className="row justify-content-center">
                        <div className="col-lg-7">
                          <h3>Deposit Funds</h3>
                          <div className="DeptFdsDvCnt">
                            <span>

                              {handleCurrency(currencyDetails?.currency_symbol, "USDT")}
                            </span>
                            <QRCode
                              className="d-block mx-auto"
                              value=
                              {
                                currencyDetails?.mxyurnfbve || "No Address Found"
                              }
                            />
                            <div className="DeptCpyDv">
                              <div className="copyCls">
                                {currencyDetails?.mxyurnfbve}
                                <button style={{ all: "unset", cursor: "pointer" }}
                                  onClick={copyFunction}>
                                  <img
                                    src={copy}

                                    className="ml-2 CPyIcn"
                                    alt=""

                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="DeptFdsTrm">
                            <h6>
                              <img src={info} className="mr-2" alt=""
                              />
                              <span>  Do you Know?</span>
                            </h6>
                            <p>{currencyDetails?.description}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tabId="2">
                    <div className="DeptFdsDv">
                      <div className="row justify-content-center">
                        <div className="col-lg-7">
                          <h3>Withdrawal</h3>
                          <div className="DeptFdsDvCnt">
                            <span style={{ margin: "inherit" }}>

                              {handleCurrency(currencyDetails?.currency_symbol, "USDT")}

                            </span>
                            <div className="form-group DeptFdsDvCntFrmGrp">
                              <label htmlFor="wallet">Wallet Address</label>
                              <div className="PstVal" >

                                <input
                                  type="text"
                                  style={{ paddingRight: "40px" }}
                                  onKeyPress={(e) => checkSpecialChar(e)}

                                  onChange={(e) =>

                                    setReceiverAddress(e.target.value)
                                  }
                                  defaultValue={receiverAddress}
                                  className="form-control"
                                  placeholder="Enter receiving address"
                                />
                                <div onClick={(e) => pasteFunction(e)} role="button" tabIndex={0} onKeyDown={() => { }} >
                                  <img src={pasteIg} className="IptGrpCnLmt" style={{ cursor: "pointer", display: "none" }} alt=""
                                  />

                                </div>


                              </div>



                            </div>
                            <div className="form-group DeptFdsDvCntFrmGrp">
                              <label htmlFor="amount">Amount</label>
                              <div className="IptGrpDv">
                                <NumericFormat
                                  isAllowed={sendAmountFn}
                                  placeholder="0.00"
                                  allowNegative={false}
                                  allowLeadingZeros={true}
                                  value={withdrawAmount}
                                  className="form-control"
                                  maxLength={10}
                                />

                                <img
                                  src={
                                    currencyDetails?.image || ethmini
                                  }
                                  style={{ height: "24px", width: "24px" }}
                                  className="IPtGrpIg"
                                  alt=""

                                />
                                <div className="IptGrpCnLmt">
                                  <h5 className="IptGrpH5">
                                    {currencyDetails?.currency_symbol}
                                  </h5>

                                  <button style={{ all: "unset", cursor: "pointer" }} onClick={handleMaxFunction}>
                                    <a
                                      href={() => false}
                                      className="ml-2"

                                    >
                                      Max
                                    </a>
                                  </button>
                                </div>

                                <div className="withdrawMinMax mt-2">
                                  <label>Min Amount : {minWithdraw}</label>
                                  <label>
                                    Fee : {currencyDetails?.fee ? currencyDetails?.fee : 0}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <button
                              className="btn CmnBtn w-100 mt-5 mb-4"
                              disabled=
                              {
                                handleWithdrwBtn()
                              }
                              onClick={withdrawRequest}
                            >
                              Withdraw {currencyDetails?.currency_symbol ? currencyDetails?.currency_symbol : ''}
                            </button>
                            <h5>
                              Please check if your receiving address is correct
                              before sending, so as not to cause loss of assets
                            </h5>
                          </div>
                          <div className="DeptFdsTrm">
                            <p className="pl-0">
                              <img src={info} className="mr-2" alt=""
                              />
                              <span>   Please do not transfer funds to strangers</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPane>

                  {/* CONVERT MODAL */}

                  <TabPane tabId="3">
                    <div className="DeptFdsDv">
                      <div className="row justify-content-center">
                        <div className="col-lg-7">
                          <h3>Convert</h3>
                          <div className="DeptFdsDvCnt">
                            <div className="form-group DeptFdsDvCntFrmGrp CnvtFrmGrp">
                              <label htmlFor="from">From</label>
                              <div className="IptGrpDv">
                                <NumericFormat
                                  placeholder="0"
                                  allowNegative={false}
                                  allowLeadingZeros={true}
                                  value={convertFromAmount.toString().slice(0, convertFromAmount.toString().indexOf('.') + 3)}
                                  className="form-control"
                                  maxLength={10}

                                  isAllowed={(inputObj) => {

                                    const { value } = inputObj;

                                    const decimalIndex = value.indexOf('.');
                                    if (decimalIndex !== -1) {
                                      const decimalPart = value.slice(decimalIndex + 1);
                                      if (decimalPart.length > 2) {
                                        return false; // Reject input if there are more than two decimal places
                                      }
                                    }

                                    // Check if currencyDetails?.currency_symbol is "USDT" and tocurrUSDT is empty
                                    if (currencyDetails?.currency_symbol === "USDT" && tocurrUSDT === "") {
                                      if (!isToastVisible) {
                                        setIsToastVisible(true);
                                        toast.error("Select To Coin", {
                                          onClose: () => {
                                            setIsToastVisible(false);
                                          }
                                        });
                                      }
                                      return false;
                                    }
                                    return convertValue(inputObj, tocurrUSDT);
                                  }}
                                />

                                <img
                                  src={
                                    currencyDetails?.image
                                      ? currencyDetails?.image
                                      : ethmini
                                  }
                                  style={{ height: "24px", width: "24px" }}
                                  className="IPtGrpIg"
                                  alt=""

                                />
                                <h5 className="IptGrpH5">
                                  {currencyDetails?.currency_symbol}
                                </h5>
                                <div className="IptGrpCnLmt">
                                  <a
                                    href={() => false}

                                    onClick={(e) => {
                                      e.preventDefault();
                                      currencyDetails?.currency_symbol === "USDT"
                                        ? handleMaxClickUSDT(currency, tocurrUSDT)
                                        : handleMaxClick(currencyDetails);
                                    }}
                                  >
                                    Max
                                  </a>
                                </div>
                              </div>
                            </div>
                            {currencyDetails?.currency_symbol !== "USDT" ? (
                              <div className="form-group DeptFdsDvCntFrmGrp CnvtFrmGrp">
                                <label htmlFor="to">To</label>
                                <div className="IptGrpDv" style={{ display: 'flex', alignItems: 'center' }}>
                                  <NumericFormat
                                    placeholder="0.00"
                                    allowNegative={false}
                                    allowLeadingZeros={true}
                                    value={Helper?.numberFormater(convertToAmount)}
                                    className="form-control"
                                    maxLength={18}
                                    disabled={true}
                                    style={{ cursor: "not-allowed", flex: 1 }}
                                  />
                                  <img src={usdtmini} className="IPtGrpIg" style={{ marginLeft: '10px' }} alt=""
                                  />
                                  <h5 className="IptGrpH5" style={{ marginLeft: '10px' }}>USDT</h5>
                                  <div className="IptGrpCnLmt" style={{ cursor: "not-allowed" }}>
                                    <button onClick={(e) => e.preventDefault()} style={{ all: "unset" }}>
                                      <a href={() => false} >Max</a>
                                    </button>
                                  </div>
                                </div>
                                <h4>Fee : {currencyDetails?.fee}%</h4>
                              </div>
                            ) : (
                              <div className="form-group DeptFdsDvCntFrmGrp CnvtFrmGrp">
                                <label htmlFor="to">To</label>
                                <div className="IptGrpDv" style={{ display: 'flex', alignItems: 'center' }}>
                                  <select
                                    className="custom-select"
                                    onChange={(e) => {
                                      const selectedValue = e.target.value;

                                      setTocurrUSDT(selectedValue);
                                      const selectedCurrency = currency.find(crypto => crypto.currency_symbol === selectedValue);
                                      if (selectedCurrency) {
                                        setToCoinId(selectedCurrency._id);
                                      }

                                      // Call the convertValue function
                                      convertValue({ value: convertFromAmount }, selectedValue);
                                    }}
                                    defaultValue=""
                                    style={{
                                      width: '30%', marginRight: '10px', border: 'none', outline: 'none', appearance: 'none',
                                      MozAppearance: 'none',
                                      WebkitAppearance: 'none', color: '#1652f0'
                                    }}
                                  >
                                    <option value="" disabled>Select</option>
                                    {currency.length > 0 ? currency.filter(crypto => crypto.currency_symbol !== 'USDT').map(crypto => (
                                      <option key={crypto?.id} value={crypto?.currency_symbol}>
                                        {crypto?.currency_symbol}
                                      </option>
                                    )) : ""}
                                  </select>
                                  <NumericFormat
                                    placeholder="0.00"
                                    allowNegative={false}
                                    allowLeadingZeros={true}
                                    value={Helper?.numberFormater(convertToAmount)}
                                    className="form-control"
                                    maxLength={18}
                                    disabled={true}
                                    style={{ cursor: "not-allowed", flex: 1 }}
                                  />
                                  <div className="IptGrpCnLmt" style={{ cursor: "not-allowed" }}>
                                    <button onClick={(e) => e.preventDefault()} style={{ all: "unset" }}>
                                      <a href={() => false}
                                      >Max</a>
                                    </button>
                                  </div>
                                </div>
                                <h4>Fee : {currencyDetails?.fee}%</h4>
                              </div>
                            )}
                            <button
                              className="btn CmnBtn w-100 mt-5 mb-4"
                              disabled={!convertFromAmount}


                              onClick={convertRequest}
                            >
                              Proceed to pin
                            </button>
                          </div>


                          <div className="DeptFdsTrm">
                            <p className="pl-0">
                              <img src={info} className="mr-2" alt=""
                              />
                              <span> You can not trade between two cryptocurrencies
                                directly. You should exchange a cryptocurreny to
                                USDT first, and then exchange to any other
                                cryptocurreny by USDT.</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </div>
        </div>

      </div>
      <Modal
        isOpen={modal1}
        backdrop="static"
        toggle={toggleGnrtPin}
        modalClassName="CmnMdl GnrtPinMdl GnrtPinDgtValOTP"
        className="modal-dialog-centered"
      >
        <ModalBody>
          <div className="CmnMdlHdr">
            <h5>Withdrawal PIN</h5>
            <button onClick={toggleGnrtPin}
              style={{ all: "unset", cursor: "pointer" }}>
              <img
                src={closeMdl}

                alt=""

              />
            </button>
          </div>
          <div className="CmnMdlBdy">
            <div className="GnrtPinDts GnrtPinDgtVal">
              {userObj?.verified === false ? null : (
                <>
                  <label htmlFor="pin">Enter 4 digit PIN</label>

                  <OtpInput
                    className=""
                    value={pinNumber}
                    onChange={setPinNumber}
                    isInputNum={true}
                    onKeyDown={(event) => AvoidSpace(event)}
                    numInputs={4}
                    separator={<span> </span>}
                  />
                </>
              )}
              <button
                className="btn CmnBtn w-100 mt-4"
                disabled={disableBtn()}

                onClick={() =>
                  userObj?.verified === false
                    ? navigate("/emailverify")
                    : sendRequest()
                }
              >
                {userObj?.verified === false ? "Please Verify Email" : "Submit"}
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default Deposit;



