import { useEffect } from "react";
function useCloseOnEscape(ref, onClose) {
    useEffect(() => {
        const handleEscape = (e) => {
            if (e.key === "Escape") {
                onClose?.();
            }
        };
        ref.current?.addEventListener("keyup", handleEscape);
        return () => {
            ref.current?.removeEventListener("keyup", handleEscape);
        };
    }, [ref]);
}
export { useCloseOnEscape };
