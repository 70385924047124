import React, { useState } from "react";


import Sidebar from "../../common/Sidebar/Sidebar";

import lftarr from "../../assets/images/left-arrow-blue.png"
import sidenav from "../../assets/images/sidenav.png"
import info from "../../assets/images/info-white.png"
import intel from "../../assets/images/intel.png"
import rgtarr from "../../assets/images/right-arrow-blue.png"
import { useNavigate } from "react-router";


const Mining = (props) => {

    const navigate = useNavigate();

    const [isActive, setIsActive] = useState(false);
    const SideNavClick = () => {
        setIsActive(!isActive);
    };


    return (

        <div className="MainInrBg">
            <div className="CntLdDv">
                <div className={isActive ? "HmPg MinePg sbMenu" : "HmPg MinePg"}>
                    <Sidebar setActive={SideNavClick} />
                    <div className="container">

                        <div className="DfltTp MineTp">
                            <div className="DfltTpIg">
                                <button style={{ all: "unset", cursor: "pointer" }} onClick={() => navigate(-1)}>
                                    <img src={lftarr} alt="" />

                                </button>
                                <button style={{ all: "unset", cursor: "pointer" }} onClick={SideNavClick}>
                                    <img src={sidenav} alt="" />

                                </button>
                            </div>
                            <h1>Mining Machine Leasing</h1>
                            <p>For easy coin mining...</p>
                            <div className="MineTpIG">
                                <button style={{ all: "unset", cursor: "pointer" }} onClick={() => navigate("/miningdetails")} >
                                    <img src={info} className="mr-2" alt="" />

                                </button>
                            </div>
                        </div>
                        <div className="ArbRgePrdDvDts">
                            <div className="ArbRgePrdDvDtsTp">
                                <button className="btn DaysBtn mr-3">30 Days</button>
                                <h4>Mining V1</h4>
                            </div>
                            <div className="MineRent">
                                <div>
                                    <h5>Rent</h5>
                                    <h6>100 USDT</h6>
                                </div>
                                <button style={{ all: "unset", cursor: "pointer" }} onClick={() => navigate("/ardetails")}>
                                    <img src={rgtarr} alt="" />

                                </button>
                            </div>
                        </div>
                        <div className="ArbRgeIntroVl">
                            <div className="ArbRgeIntroVlDts">
                                <div className="ArbRgeIntroVlDtsR">
                                    <div>
                                        <a href={() => false}>Cloud mining machine</a>
                                        <h6>Safe and stable money making tool</h6>
                                    </div>
                                    <img src={intel} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>

    );

}

export default Mining;