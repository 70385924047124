import React, { useEffect } from "react";



import lftarr from "../../assets/images/left-arrow.png"
import loan from "../../assets/images/arbi-close.png"

import dwnarrblue from "../../assets/images/down-arrow-blue.png"
import sucldr from "../../assets/images/successloader.gif"
import { useNavigate } from "react-router";
import { useCustomizeContext } from "../../Services/Context";

import Helper from "../../Services/Helper";


const Withdrawrequest = (props) => {

    const { withdrawmessage: { withdrawdata } } = useCustomizeContext();
    const navigate = useNavigate();
    useEffect(() => {
        if (withdrawdata == null) {
            navigate("/wallet")
        }
    }, []);
    return (

        <div className="MainInrBg">
            {/* <div className="CntLdDv"> */}
            <div className="HmPg">
                <div className="container">

                    <div className="DfltTp" style={{ background: 'transparent', marginTop: '0' }}>
                        <div className="DfltTpIg mb-4">
                            <button style={{ all: "unset", cursor: "pointer" }} onClick={() => navigate(-1)}>
                                <img src={lftarr} alt="" />

                            </button>
                            <span className="wdtitle">Withdrawal</span>
                            <button style={{ all: "unset", cursor: "pointer" }} onClick={() => navigate(-1)}>
                                <img src={loan} alt="" />

                            </button>
                        </div>
                    </div>
                    <div className="WdSucSec">
                        <img src={sucldr} className="d-block mx-auto" alt="" />
                        <h3>Withdrawal Submitted</h3>
                        <h4>Your request to send {Helper.formatNumberWithCommas(withdrawdata?.amount)} {withdrawdata?.symbol} is pending</h4>
                        <h5>{Helper.formatDated(withdrawdata?.time)}</h5>
                        <button style={{ all: "unset", cursor: "pointer" }} onClick={() => navigate(`/transactionhistory/${withdrawdata?.currencyId}?tab=2`)}>
                            <a href={() => false}>View Details<img src={dwnarrblue} className="ml-2" alt="" /></a>
                        </button>
                    </div>
                </div>
            </div>
        </div>


        // </div>

    );

}

export default Withdrawrequest;
