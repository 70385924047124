import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/scss/style.scss';

import { CustomizeProvider } from './Services/Context';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

// import { SocketContext, socket } from "../src/Services/Context/socket";
import Footer from './common/Footer/Footer';


export const queryClient = new QueryClient();





const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <CustomizeProvider>
    <QueryClientProvider client={queryClient}>
      {/* <SocketContext.Provider value={socket}> */}
        {(() => {
          if (window.location.pathname !== '/landing') {
            return typeof window.ethereum !== "undefined" ? '' : window.location.href = '/landing'
          }
        })()}
        <App />
        <Footer />
      {/* </SocketContext.Provider> */}
    </QueryClientProvider>
  </CustomizeProvider>
);


reportWebVitals();
