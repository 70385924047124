import React from 'react';
import LineChart from 'react-linechart';

const Minichart = ({ chartdata, chartcolor }) => {
  return (
    <div>
      <LineChart
        width={150}
        height={60}
        xLabel="Date"
        yLabel="Value"
        hideXLabel={false}
        hidePoints={true}
        hideXAxis={true}
        hideYAxis={true}
        pointRadius={10}
        hideLines={false}
        data={[{ color: chartcolor, points: chartdata }]}
      />
    </div>
  );
};

export default Minichart;