import { useEffect } from "react";
function useMenuHidden(menuLeft, menuWidth, cb) {
    useEffect(() => {
        let dir;
        if (menuLeft < 0) {
            dir = "left";
        }
        else if (menuLeft + menuWidth > window.innerWidth) {
            dir = "right";
        }
        else {
            dir = null;
        }
        cb(dir);
    }, [menuLeft, menuWidth]);
}
export { useMenuHidden };
