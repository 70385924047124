import React, { useState } from "react";
import lftarr from "../../assets/images/left-arrow.png"
import refer from "../../assets/images/refer.png"
import loan from "../../assets/images/buy-coin-list.png"
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Referral = (props) => {
  const navigate=  useNavigate()
  const [isActive, setIsActive] = useState(false);

    const copyFunction = () => {

        const addressToCopy = '0x3sd5v4sd74gvbs687bg3sad4b6...ae54g';
        navigator.clipboard.writeText(addressToCopy);
        toast.info("Address is Copied !");
    }

    const SideNavClick = () => {
        setIsActive(!isActive);
      };
    return (

        <div className="MainInrBg">
            <div className="CntLdDv">
                <div className="EmailVrfyPg RefrlPg">
                    <div className="container">
                        <div className="EmailVrfyTp RefrlTp">
                            {/* <img src={lftarr} alt="" onClick={navigte(-1)}/>
                            <h4>Invite Friends</h4>
                            <img src={loan} alt="" /> */}
                               <button onClick={() => navigate(-1)} style={{ all: "unset",cursor:"pointer" }}>
                  <img src={lftarr} alt=""
                    aria-label="Go Back" />
                </button>
                <h4>Invite Friends</h4>

                <button style={{ all: "unset" }}>
                  <img src={loan} alt=""

                    aria-label="Side" />
                </button>
                        </div>
                        <div className="RefrlBg">
                            <div className="row justify-content-center">
                                <div className="col-lg-8">
                                    <div className="RefrlIncVal">
                                        <h5>Total Income :</h5>
                                        <h5 className="Vl">0.00 USDT</h5>
                                    </div>
                                    <div className="RefrlIncVal">
                                        <h5>Direct referral commission :</h5>
                                        <h5 className="Vl">0.00 USDT</h5>
                                    </div>
                                    <div className="RefrlIncVal">
                                        <h5>Indirect referral commission :</h5>
                                        <h5 className="Vl">0.00 USDT</h5>
                                    </div>
                                    <img src={refer} className="RefCmsnDvIg" alt="" />
                                    <h4>Get Referral Rewards</h4>
                                    <p> When your friends participate in AI arbitrage and rent mining machines,<br />you can get referral rewards</p>
                                    <div className="DeptCpyDv w-100">
                                        <div className="row">

                                            {/* <div className="DeptCpyDv">
                                                <button className="copyCls btn btn-none" onClick={copyFunction}>{'0x3sd5v4sd74gvbs687bg3sad4b6...ae54g'}</button>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>

    );

}

export default Referral;