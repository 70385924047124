import React, { useEffect } from "react";


import coinbase from "../../assets/images/coinbase.png"

import appstore from "../../assets/images/apple-store.png"
import playstore from "../../assets/images/google-play.png"
import lndTk from "../../assets/images/land-tick.png"
import lndPhn from "../../assets/images/land-phone.png"
import { usePostRequest } from "../../Services/Queries";
import { QueryKeys } from "../../Services/QueryKeys";
import USER_APIS from "../../Services/APIS";


const Landing = (props) => {

    let { mutateAsync: handleVPN } = usePostRequest(
        QueryKeys?.vpnCheck
      );

let payload={
    language :"en"
}
      let postObj = {
        Api: USER_APIS?.VPNSTATUS,
        Payload: payload,
        Type: "",
      };

      let vpnproxy= async()=>{
         await handleVPN(postObj);
        }

useEffect(()=>{
    vpnproxy()
},[])

    if (localStorage.getItem("uid")) {
        window.location.href = ("/")
    }

    const openAppStore = () => {
        window.location.href("https://apps.apple.com/us/app/coinbase-wallet-nfts-crypto/id1278383455", "_blank");
    };

    const openPlayStore = () => {
        window.location.href("https://play.google.com/store/apps/details?id=org.toshi&hl=en", "_blank");
    };

    return (

        <div className="LndPg">
            <div className="LndPgInr">
                <div className="LndInrImgTp">
                    <h2><img src={coinbase} className="LndInrImgTpIg" alt="" />Coinbase wallet</h2>
                    <h1>Get Coinbase Wallet</h1>
                </div>
                <h3>The easiest and most secure crypto wallet</h3>
                <div className="LndPgIntrLst">
                    <ul>
                        <li><img src={lndTk} alt="" /><span>Store all your crypto and NFTs in one place</span></li>
                        <li><img src={lndTk} alt="" /><span>Trade 500+ assets on DEXes and earn interest</span></li>
                        <li><img src={lndTk} alt="" /><span>No Coinbase account required</span></li>
                    </ul>
                </div>
                <div className="LndPgIntrIg">
                    <img src={lndPhn} alt="" />
                </div>
                <div className="LndPgPlyStr">
                    <button onClick={openAppStore} style={{ all: "unset", cursor: 'pointer' }}>
                        <img src={appstore} alt="App Store" />
                    </button>
                    <button style={{ all: "unset", cursor: 'pointer' }} onClick={openPlayStore}>
                        <img src={playstore} alt="Play Store" />

                    </button>
                </div>
            </div>
        </div>

    );

}

export default Landing;