export const getStartingPosition = (pos, offset = 10) => {
    switch (pos) {
        case "top left":
            return `left: ${offset}px;top: ${offset}px;`;
        case "top right":
            return `right: ${offset}px;top: ${offset}px;`;
        case "bottom left":
            return `left: ${offset}px;bottom: ${offset}px;`;
        case "bottom right":
            return `right: ${offset}px;bottom: ${offset}px;`;
        default:
            return `left: ${offset}px;top: ${offset}px;`;
    }
};
export const getLeft = (left, dimension) => {
    if (left < 0) {
        return 0;
    }
    else if (left + dimension > window.innerWidth) {
        return window.innerWidth - dimension;
    }
    else {
        return left;
    }
};
export const getTop = (top, dimension) => {
    if (top < 0) {
        return 0;
    }
    else if (top + dimension > window.innerHeight) {
        return window.innerHeight - dimension;
    }
    else {
        return top;
    }
};
