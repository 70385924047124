import React, { useEffect, useState } from "react";

import Sidebar from "../../common/Sidebar/Sidebar";

import lftarr from "../../assets/images/left-arrow.png";
import sidenav from "../../assets/images/sidenavbuy.png";
import wltbg from "../../assets/images/wallet-bg.svg";

import { usePostRequest } from "../../Services/Queries";

import { useNavigate } from "react-router";
import { QueryKeys } from "../../Services/QueryKeys";
import USER_APIS from "../../Services/APIS";

import { useCustomizeContext } from "../../Services/Context";
import Helper from "../../Services/Helper";

const Wallet = (props) => {
  let navigate = useNavigate();

  const {
    loaderProperty: { setLoading },
  } = useCustomizeContext();


  const [isActive, setIsActive] = useState(false);

  const [currencyDetails, setCurrencyDetails] = useState([]);

  let { mutateAsync: getWallet } = usePostRequest(
    QueryKeys?.GET_WALLET_DETAILS_KEY
  );

  const SideNavClick = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {
    getCurrencyDetails();
  }, []);

  const getCurrencyDetails = async () => {
    let payload = {
      search: '',
      page: 1,
      limit: 15,
    };

    let postObj = {
      Api: USER_APIS?.GET_WALLET_DETAILS,
      Payload: payload,
      Type: "",
    };
    setLoading(true);
    let res = await getWallet(postObj);
    if (res?.status === true) {
      setCurrencyDetails(res?.data);
    }
    setLoading(false);
  };

  const navigateWallet = (id) => {
    navigate(`/deposit/${id}`);
  };
  const formatAmount = (amount) => {
    if (!isNaN(+amount)) {

      return Helper?.numberFormater((+amount).toFixed(2))

    }
    else {
      return '0.00';
    }
  };

  const formatAmounWithSymbol = (amount, symbol) => {
    if (!isNaN(+amount)) {
      return `${Helper?.numberFormater((+amount).toFixed(2))} ${symbol}`
    }
    return "0.00";
  };


  return (

    <div className="MainInrBg">
      <div className="CntLdDv">
        <div className={isActive ? "HmPg  sbMenu" : "HmPg"}>
          <Sidebar setActive={SideNavClick} />
          <div className="container">

            <div
              className="DfltTp WltTp"
              style={{ background: "transparent" }}
            >
              <div className="DfltTpIg" >
                <button onClick={() => navigate(-1)} style={{ all: "unset" }}>
                  <img src={lftarr} alt=""
                    aria-label="Go Back" />
                </button>
                <button onClick={SideNavClick} style={{ all: "unset" }}>
                  <img src={sidenav} alt=""

                    aria-label="Side" />
                </button>
              </div>
              <div className="d-flex justify-content-between align-items-center mt-4">
                <div>

                  <h1 className="my-2">Send Crypto Now</h1>
                  <h6>Choose a wallet to send crypto from.</h6>
                </div>
                <img src={wltbg} className="WltBg" alt="" />
              </div>
            </div>
            <div className="HmMrktTbl">
              <div className="HmMrktTbs">
                <div className="table-responsive">
                  <table className="table">
                    <tbody>
                      {currencyDetails?.length > 0 ? (
                        currencyDetails?.map((temp, index) => (
                          <tr
                            key={temp._id}
                            onClick={() => navigateWallet(temp?.cid)}
                            style={{ cursor: "pointer" }}
                          >
                            <td>
                              <div className="CnDts">
                                <img src={temp?.image} className="mr-2" alt="" />
                                <div>
                                  <h5>{temp?.symbol} Wallet</h5>
                                  <h6>{temp?.name}</h6>
                                </div>
                              </div>
                            </td>
                            <td className="text-right">
                              <h5>

                                {formatAmount(temp?.amount)}
                              </h5>

                              <h6>

                                {formatAmounWithSymbol(temp.amount, temp.symbol)}
                              </h6>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <div className="NoRrdFnd">No Currency Found</div>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};
export default Wallet;
