import { useEffect } from "react";
function useCloseOnClick(ref, menuOpen, onClose) {
    useEffect(() => {
        const handleClick = (e) => {
            if (ref.current && !ref.current.contains(e.target) && menuOpen) {
                onClose();
            }
        };
        document.addEventListener("pointerdown", handleClick);
        return () => {
            document.removeEventListener("pointerdown", handleClick);
        };
    }, [ref, onClose, menuOpen]);
}
export { useCloseOnClick };
