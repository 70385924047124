import React, { useState } from "react";
import lftarr from "../../assets/images/left-arrow.png";
import { useNavigate } from "react-router";
import Sidebar from "../../common/Sidebar/Sidebar";
import sidenav from "../../assets/images/sidenavbuy.png"
import { useGetRequest } from "../../Services/Queries";
import USER_APIS from "../../Services/APIS";
import { QueryKeys } from "../../Services/QueryKeys";




const ArbitrageDetails = (props) => {



    let requestData = {
        Api: USER_APIS?.GET_MINING_CMS,
        Query_Key: QueryKeys?.GET_MINING_DATA,
    };

    let { data } = useGetRequest(requestData);
    let miningData
    if (data?.status) {
        miningData = data?.data.find((item) => item.title == "Mining")
    }

    let navigate = useNavigate();
    const [isActive, setIsActive] = useState(false);
    const SideNavClick = () => {
        setIsActive(!isActive);
    };
    return (

        <div className="MainInrBg">
            <div className="CntLdDv">
                <div className={isActive ? "HmPg MinePg sbMenu" : "HmPg MinePg"}>
                    <Sidebar setActive={SideNavClick} />
                    <div className="JnArbRgePg ArbRgeDtsPg">
                        <div className="container">
                            <div className="JnArbRgeTp ArbRgeDtsPgTp">
                                <div className="d-flex justify-content-between align-items-center">
                                    <button onClick={() => navigate(-1)} style={{ all: "unset", cursor: "pointer" }}>
                                        <img src={lftarr} />

                                    </button>
                                    <h2 style={{ color: '#353f52' }}>Mining Introduction</h2>
                                    <button onClick={SideNavClick} style={{ all: "unset", cursor: "pointer" }}>
                                        <img src={sidenav} />

                                    </button>
                                </div>

                            </div>
                            <div className="JnArbRgeDur ArbRgeDtsPgRls" dangerouslySetInnerHTML={{ __html: miningData?.content }}>


                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div >

    );

}

export default ArbitrageDetails;