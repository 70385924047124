
import React, { } from "react";
import { Link } from 'react-router-dom';

import { Menu } from 'react-float-menu';

const CommonChat = (props) => {


    return (
        <div>
            <Menu
                startPosition="bottom right"
            >
                <span>
                    <div className="DfltCntLd" style={{ cursor: "pointer" }} > <Link to='/chat' target="_top">
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_500_40157)">
                                <path d="M27 18L22.9091 13.9091H13.3636C13.002 13.9091 12.6551 13.7654 12.3994 13.5097C12.1437 13.254 12 12.9071 12 12.5455V4.36364C12 4.00198 12.1437 3.65513 12.3994 3.3994C12.6551 3.14367 13.002 3 13.3636 3H25.6364C25.998 3 26.3449 3.14367 26.6006 3.3994C26.8563 3.65513 27 4.00198 27 4.36364V18Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M18 18.8182V21.5455C18 21.9071 17.8563 22.254 17.6006 22.5097C17.3449 22.7654 16.998 22.9091 16.6364 22.9091H7.09091L3 27V13.3636C3 13.002 3.14367 12.6551 3.3994 12.3994C3.65513 12.1437 4.00198 12 4.36364 12H7.09091" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </g>
                            <defs>
                                <clipPath id="clip0_500_40157">
                                    <rect width="30" height="30" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </Link></div>
                </span>
            </Menu>
        </div>
    );
}
export default CommonChat;