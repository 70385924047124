import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from "classnames";
import { useCallback, useEffect, useMemo, useRef, useState, } from "react";
import { useMenuHidden } from "../../effects/useMenuHidden";
import { usePosition } from "../../effects/usePosition";
import { defaultTheme } from "../../utils/theme-default";
import { MenuContext } from "../context";
import { MenuContainer } from "../menu-container/menu-container";
import styles from "./main.module.scss";
const MenuHead = ({ dimension = 30, children, shape = "circle", items = [], startPosition = "top left", theme = defaultTheme, disableHeader = false, width = 250, onSelect, startOffset = 10, closeOnClickOutside = true, autoFlipMenu = true, bringMenuToFocus = true, iconSize = "1rem", pin, RTL = false, }) => {
    const [pressedState, setPressedState] = useState(false);
    const [openMenu, setMenuOpen] = useState(null);
    const [headPosition, setHeadPosition] = useState({ x: 0, y: 0 });
    const [closeMenuImmediate, setCloseMenuImmediate] = useState(false);
    const [isDragged, setIsDragged] = useState(false);
    const finalTheme = useMemo(() => ({ ...defaultTheme, ...theme }), []);
    const [menuDimension, setMenuDimension] = useState({ height: 0, width: 0 });
    const [menuPosition, setMenuPosition] = useState({ bottom: 0, left: 0, top: 0 });
    const [menuHiddenTowards, setMenuHiddenTowards] = useState();
    const headHalfWidth = useMemo(() => Math.round(dimension / 2), [dimension]);
    const isFirstRender = useRef(true);
    const { setup, ref } = usePosition({
        dimension,
        onClosed: () => {
            setMenuOpen(false);
            setPressedState(false);
        },
        onDragEnd: ({ left, top }) => {
            setHeadPosition({
                x: left || 0,
                y: (top || 0) + dimension + 10,
            });
            setMenuOpen(false);
            setPressedState(false);
            setIsDragged(false);
        },
        onDragStart: ({ left, top }) => {
            setHeadPosition({
                x: left || 0,
                y: (top || 0) + dimension + 10,
            });
            setCloseMenuImmediate(true);
            setMenuOpen(false);
            setIsDragged(true);
        },
        onInit: ({ left, top }) => {
            setHeadPosition({
                x: left || 0,
                y: (top || 0) + dimension + 10,
            });
        },
        onPointerDown: () => {
            setPressedState(true);
            setCloseMenuImmediate(false);
        },
        onPointerUp: useCallback(() => {
            setPressedState(false);
            setMenuOpen((prev) => !prev);
        }, []),
        pin,
        startOffset,
        startPosition,
    });
    useMenuHidden(menuPosition.left, menuDimension.width, (dir) => {
        setMenuHiddenTowards(dir);
    });
    const style = useMemo(() => ({
        "--dimension": `${dimension}px`,
        "--rc-fltmenu-primary": finalTheme.primary,
        "--rc-fltmenu-width": `${width}px`,
    }), [finalTheme.primary]);
    const pressedClass = useMemo(() => {
        if (isFirstRender.current) {
            return "";
        }
        return pressedState ? styles.pressed : styles.released;
    }, [pressedState]);
    const menuHeadClass = useMemo(() => {
        return classNames(styles.menu_head, pressedClass, isDragged ? styles.is_dragged : "", {
            [styles[shape]]: true,
        });
    }, [pressedClass, isDragged]);
    const handleMenuClose = useCallback(() => {
        setMenuOpen(false);
        setCloseMenuImmediate(false);
        ref?.current?.focus();
    }, []);
    const shouldFlipVertical = useMemo(() => {
        return (autoFlipMenu &&
            headPosition.y + dimension + menuDimension.height > window.innerHeight);
    }, [
        headPosition.x,
        headPosition.y,
        JSON.stringify(menuDimension),
        openMenu,
        autoFlipMenu,
    ]);
    const onMenuRender = useCallback((menuHeight, menuWidth) => setMenuDimension({ height: menuHeight, width: menuWidth }), []);
    useEffect(() => {
        setMenuPosition({
            left: Math.round(headPosition.x - (Math.round(menuDimension.width / 2) - headHalfWidth)),
            [shouldFlipVertical ? "bottom" : "top"]: !shouldFlipVertical
                ? headPosition.y + 10
                : Math.abs(window.innerHeight - headPosition.y) + dimension + 20,
        });
    }, [
        shouldFlipVertical,
        headPosition.x,
        headPosition.y,
        menuDimension.width,
        headHalfWidth,
    ]);
    const shouldAdjustMenuPosition = useMemo(() => !!(!isFirstRender.current && bringMenuToFocus && ref?.current), [openMenu, bringMenuToFocus]);
    useEffect(() => {
        if (!shouldAdjustMenuPosition) {
            return;
        }
        const alignedTo = startPosition.split(" ")[1];
        const { width: menuWidth } = menuDimension;
        const { innerWidth } = window;
        const headRef = ref.current;
        if (menuHiddenTowards === "left") {
            setMenuPosition({
                left: startOffset,
            });
            headRef.style.cssText += `left: ${Math.round(menuWidth / 2) - headHalfWidth + startOffset}px;`;
        }
        else if (menuHiddenTowards === "right") {
            setMenuPosition({
                left: innerWidth - menuWidth - startOffset,
            });
            headRef.style.cssText += `left: ${Math.round(innerWidth - menuWidth / 2) - headHalfWidth - 10}px;`;
        }
        else if (alignedTo === "left" && headPosition.x <= startOffset && pin) {
            headRef.style.cssText += `left: ${startOffset}px;`;
            setMenuPosition((prev) => ({
                ...prev,
                left: -menuWidth,
            }));
        }
        else if (alignedTo === "right" &&
            headPosition.x >= innerWidth - dimension - startOffset &&
            pin) {
            headRef.style.cssText += `left: ${innerWidth - dimension - startOffset}px;`;
            setMenuPosition((prev) => ({
                ...prev,
                left: innerWidth,
            }));
        }
    }, [openMenu, headPosition.x, shouldAdjustMenuPosition]);
    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
        }
    }, []);
    const handleSelection = useCallback((path) => {
        onSelect?.(path);
        handleMenuClose();
    }, []);
    return (_jsxs(MenuContext.Provider, { value: {
            RTL,
            closeOnClickOutside,
            dimension,
            disableHeader,
            iconSize,
            items,
            shape,
            theme: finalTheme,
            width,
        }, children: [_jsx("div", { className: classNames(menuHeadClass), "data-cy": "rc-fltmenu-head", ref: setup, role: "button", style: style, tabIndex: 0, children: _jsx("span", { className: classNames(styles.icon_container), "data-cy": "rc-fltmenu-icon", children: children }) }), _jsx(MenuContainer, { closeImmediate: closeMenuImmediate, disableHeader: disableHeader, headPosition: headPosition, menuPosition: menuPosition, open: openMenu, shouldFlipVertical: shouldFlipVertical, onClose: handleMenuClose, onMenuRender: onMenuRender, onSelect: handleSelection })] }));
};
export { MenuHead };
