import React, { Fragment, useState } from "react";
import { Modal, ModalBody } from 'reactstrap';
import lftarr from "../../assets/images/left-arrow-blue.png"
import success from "../../assets/images/success.png"
import star from "../../assets/images/star.png"
import lease from "../../assets/images/lease.png"

import tick from "../../assets/images/tick.png"
import { useNavigate } from "react-router-dom";


const MiningDetails = (props) => {

    const [modal, setModal] = useState(false);
    const toggleMiningConfrim = () => setModal(!modal);
    const navigate = useNavigate()

    return (
        <Fragment>
            <div className="MainInrBg">
                <div className="CntLdDv">
                    <div className="MineDtsPg">
                        <div className="container">

                            <div className="MineDtsTp">
                                <button style={{ all: "unset", cursor: "pointer" }} onClick={() => navigate(-1)} >
                                    <img src={lftarr} className="mb-4" alt="" />
                                </button>
                                <h2>Leasehold Mining</h2>
                            </div>
                            <div className="JnArbRgeDur">
                                <div className="MineVer">
                                    <div>
                                        <h4>Mining V1</h4>
                                        <h5>100 USDT</h5>
                                        <div className="d-flex align-items-center">
                                            <img src={star} className="mr-2" alt="" />
                                            <img src={star} className="mr-2" alt="" />
                                            <img src={star} className="mr-2" alt="" />
                                            <img src={star} className="mr-2" alt="" />
                                            <img src={star} className="mr-2" alt="" />
                                        </div>
                                    </div>
                                    <div className="MineIncDec">
                                        <span className="Vl">-</span>
                                        <span>01</span>
                                        <span className="Vl">+</span>
                                    </div>
                                </div>
                                <div className="JnArbRgeCnTyps">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-6">
                                            <div className="JnArbRgeCnTypsCt">
                                                <p className="mb-4 MineIntro">Introduction</p>
                                                <div className="JnArbRgeCnTypsVl">
                                                    <h5>Output</h5>
                                                    <h6>1.2000% USDT/Day</h6>
                                                </div>
                                                <div className="JnArbRgeCnTypsVl">
                                                    <h5>Computing Power</h5>
                                                    <h6>18000 Th/s</h6>
                                                </div>
                                                <div className="JnArbRgeCnTypsVl">
                                                    <h5>Power</h5>
                                                    <h6>180000W</h6>
                                                </div>
                                                <div className="JnArbRgeCnTypsVl">
                                                    <h5>Lease Cycle</h5>
                                                    <h6>1Days</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col-lg-6">
                                            <div className="JnArbRgeRls">
                                                <p>Choose Us</p>
                                                <p><img src={tick} className="mr-2" alt="" />Daily income is sent to your USDT wallet</p>
                                                <p><img src={tick} className="mr-2" alt="" />Zero risk of your investment funds</p>
                                                <p><img src={tick} className="mr-2" alt="" />You can get your funds back anytime</p>
                                                <p><img src={tick} className="mr-2" alt="" />Artificial intelligence works 24 hours a day</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col-lg-6">
                                            <button className="LseNw mtBtn" onClick={toggleMiningConfrim}>
                                                <div className="LseNwL">
                                                    <img src={lease} className="mr-2" alt="" />
                                                    <h5>Lease Now</h5>
                                                </div>
                                                <div className="LseNwR">
                                                    <h5>100 USDT</h5>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Modal isOpen={modal} toggle={toggleMiningConfrim} modalClassName="CmnMdl MineConfirmMdl" className="modal-dialog-centered">
                <ModalBody>
                    <div className="CmnMdlBdy MineConfirmBdy">
                        <img src={success} className="d-block mx-auto" alt="" />
                        <h5><span>100</span> USDT</h5>
                        <h6>Your escrow amount Mining<br />machine leasing</h6>
                        <button className="btn CmnBtn w-100">Confirm</button>
                        <p>The daily income of the miner will be automatically deposited into your wallet account</p>
                    </div>
                </ModalBody>
            </Modal>
        </Fragment>
    );

}

export default MiningDetails;